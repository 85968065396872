import * as yup from "yup";

export const formHeaders = [
  "#",
  "Business Legal Name",
  "Authorized Signer",
  "IT Contact - Name",
  "IT Contact - Phone",
  "IT Contact - Email",
  "Other Brands",
];

export const formSchema = yup.object().shape({
  businessLegalName: yup.string().required(),
  authorizedSigner: yup.string().required(),
  itName: yup.string().required(),
  itPhone: yup.string().required("Required field"),
  itEmail: yup.string().required("Required field"),
  otherBrands: yup.string().required(),
});

export const formSchemaRows = yup.array().of(
  yup.object().shape({
    key: yup.number().required(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zip: yup
      .string()
      .matches(/^$|^\d{5}(?:[-\s]\d{4})?$/)
      .notRequired(),
    phone: yup.string(),
  })
);
